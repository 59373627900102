/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { MarkerPin042 } from "./../icons/MarkerPin042";
import { MarkerPin052 } from "./../icons/MarkerPin052";
import { MarkerPin062 } from "./../icons/MarkerPin062";
import "./dashboarddata.css";

export const Group = ({
  className,
  markerPin052Opacity,
  markerPin052Color = "#00A187",
  markerPin062Opacity,
  markerPin062Color = "#418A69",
}) => {
  return (
    <div className={`group ${className}`}>
      <div className="overlap">
        <div className="overlap-group-wrapper">
          <div className="overlap-group">
            <div className="text-wrapper">Districts</div>
            <div className="div">60</div>
          </div>
        </div>
        <MarkerPin052 className="marker-pin" color={markerPin052Color} opacity={markerPin052Opacity} />
      </div>
      <div className="overlap-2">
        <div className="overlap-wrapper">
          <div className="overlap-3">
            <div className="text-wrapper-2">Taluka</div>
            <div className="text-wrapper-3">12</div>
          </div>
        </div>
        <MarkerPin062 className="marker-pin-06-2" color={markerPin062Color} opacity={markerPin062Opacity} />
      </div>
      <div className="overlap-4">
        <div className="overlap-wrapper">
          <div className="overlap-3">
            <div className="text-wrapper-4">Gram Sabha</div>
            <div className="text-wrapper-5">06</div>
          </div>
        </div>
        <MarkerPin042 className="marker-pin-04-2" color="#C63E13" />
      </div>
    </div>
  );
};

Group.propTypes = {
  markerPin052Opacity: PropTypes.string,
  markerPin052Color: PropTypes.string,
  markerPin062Opacity: PropTypes.string,
  markerPin062Color: PropTypes.string,
};
