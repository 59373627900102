/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { IconMenuDashboardActive } from "../../icons/IconMenuDashboardActive";
import "./style.css";

export const IconMenuDashboard = () => {
  return <IconMenuDashboardActive className="icon-menu-dashboard-active" />;
};
