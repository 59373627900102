import React from "react";
import "./taluka.css"; // Import your custom CSS for styling
import AddtalukaIcon from "./img/group-48128.png";

export const Taluka = () => {
  return (
    <div className="taluka">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="group-2">
            {/* <div className="HEADER">
              <div className="text-wrapper-2">Taluka</div>
            </div> */}
          </div>
          <div className="group-11">
            <div className="group-12">
              <div className="group-13">
                <div className="group-14">
                  <div className="overlap-group-4">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.8102 19.5901L14.6466 14.5411C15.9988 13.0812 16.8296 11.1506 16.8296 9.02607C16.829 4.4814 13.0618 0.797729 8.41448 0.797729C3.76713 0.797729 0 4.4814 0 9.02607C0 13.5708 3.76713 17.2544 8.41448 17.2544C10.4225 17.2544 12.2641 16.5643 13.7107 15.4169L18.8944 20.4859C19.147 20.7331 19.5571 20.7331 19.8097 20.4859C20.0628 20.2387 20.0628 19.8374 19.8102 19.5901ZM8.41448 15.9884C4.48232 15.9884 1.29469 12.8713 1.29469 9.02607C1.29469 5.18086 4.48232 2.06371 8.41448 2.06371C12.3467 2.06371 15.5343 5.18086 15.5343 9.02607C15.5343 12.8713 12.3467 15.9884 8.41448 15.9884Z"
                        fill="#413EF7"
                      />
                    </svg>

                    <div className="text-wrapper-5">Search Taluka.....</div>
                  </div>
                </div>
                <img className="group-15" alt="Group" src={AddtalukaIcon} />
              </div>

              <div className="group-16">
                <div className="group-17">
                  <div className="group-18">
                    <div className="overlap-3">
                      <div className="group-19">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-6">Basti</div>
                          <div className="text-wrapper-7">9,158</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">40</div>
                          <div className="text-wrapper-15">Harriya</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-16">H</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-21">
                    <div className="overlap-5">
                      <div className="group-22">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-6">Hathras</div>
                          <div className="overlap-6">
                            <div className="text-wrapper-17">12,943</div>
                            <div className="text-wrapper-17">12,943</div>
                          </div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">115</div>
                          <div className="text-wrapper-15">Sasni</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-16">S</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-23">
                    <div className="overlap-7">
                      <div className="group-22">
                        <div className="overlap-group-5">
                          <div className="overlap-8">
                            <div className="text-wrapper-17">Mahoba</div>
                            <div className="text-wrapper-17">Mahoba</div>
                          </div>
                          <div className="text-wrapper-18">20,096</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">227</div>
                          <div className="text-wrapper-19">Kulpahar</div>
                        </div>
                      </div>
                      <div className="group-24">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-20">Rampur</div>
                          <div className="text-wrapper-18">40,009</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">150</div>
                          <div className="text-wrapper-15">Tanda</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-16">K</div>
                        </div>
                      </div>
                      <div className="group-25">
                        <div className="overlap-4">
                          <div className="text-wrapper-21">T</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-26">
                    <div className="overlap-3">
                      <div className="group-19">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-20">Amethi</div>
                          <div className="text-wrapper-18">62,709</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">198</div>
                          <div className="text-wrapper-22">Tiloi</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-23">T</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-27">
                    <div className="overlap-3">
                      <div className="group-19">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-6">Bhagpat</div>
                          <div className="text-wrapper-7">44,189</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">48</div>
                          <div className="text-wrapper-24">Khekada</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-16">K</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-28">
                    <div className="overlap-3">
                      <div className="group-19">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-6">Hardoi</div>
                          <div className="text-wrapper-18">2,97,209</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">416</div>
                          <div className="text-wrapper-22">Sandila</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-16">S</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-29">
                    <div className="overlap-3">
                      <div className="group-19">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-6">Banda</div>
                          <div className="text-wrapper-7">15,156</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">100</div>
                          <div className="text-wrapper-15">Baberu</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-16">B</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-30">
                    <div className="overlap-3">
                      <div className="group-19">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-6">Gonda</div>
                          <div className="text-wrapper-25">8,865</div>
                          <div className="overlap-group-6">
                            <div className="text-wrapper-8">Population</div>
                            <div className="text-wrapper-9">:</div>
                          </div>
                          <div className="text-wrapper-10">District</div>
                          <div className="text-wrapper-11">Villages</div>
                          <div className="text-wrapper-12">:</div>
                          <div className="text-wrapper-13">:</div>
                          <div className="text-wrapper-14">463</div>
                          <div className="text-wrapper-26">Mankapur</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-4">
                          <div className="text-wrapper-27">M</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rectangle-wrapper">
                <div className="rectangle-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
