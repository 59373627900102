import React from "react";
import { TextMedium } from "./components/TextMedium";
import { Users01 } from "./icons/Users01";
import { Settings01 } from "./icons/Settings01";
import { LogOut04 } from "./icons/LogOut04";
import { MarkerPin05 } from "./icons/MarkerPin05";
import { MarkerPin061 } from "./icons/MarkerPin061";
import { File031 } from "./icons/File031";
import { HelpCircle } from "./icons/HelpCircle";
import { Users031 } from "./icons/Users031";
import AdduserIcon from "./img/group-48124.png";
import "./rolemanagement.css";

export const Role = () => {
  return (
    <div className="ROLE">
      <div className="div">
        <div className="group-10">
          <div className="group-11">
            <div className="div-wrapper">
              <div className="overlap-group-2">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.8102 19.5901L14.6466 14.5411C15.9988 13.0812 16.8296 11.1506 16.8296 9.02607C16.829 4.4814 13.0618 0.797729 8.41448 0.797729C3.76713 0.797729 0 4.4814 0 9.02607C0 13.5708 3.76713 17.2544 8.41448 17.2544C10.4225 17.2544 12.2641 16.5643 13.7107 15.4169L18.8944 20.4859C19.147 20.7331 19.5571 20.7331 19.8097 20.4859C20.0628 20.2387 20.0628 19.8374 19.8102 19.5901ZM8.41448 15.9884C4.48232 15.9884 1.29469 12.8713 1.29469 9.02607C1.29469 5.18086 4.48232 2.06371 8.41448 2.06371C12.3467 2.06371 15.5343 5.18086 15.5343 9.02607C15.5343 12.8713 12.3467 15.9884 8.41448 15.9884Z"
                    fill="#413EF7"
                  />
                </svg>
                <div className="text-wrapper-4">Search Role</div>
              </div>
            </div>
            <img className="img" alt="Group" src={AdduserIcon} />
          </div>
          <div className="group-12">
            <div className="group-13">
              <div className="group-14">
                <div className="text-wrapper-5">Working</div>
                <div className="text-wrapper-6">Not Working</div>
                <div className="ellipse" />
                <div className="ellipse-2" />
              </div>
              <div className="group-15">
                <div className="group-16">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-7">Henry Jack</div>
                    <div className="text-wrapper-8">ANM</div>
                    <div className="ellipse-wrapper">
                      <div className="ellipse-3" />
                    </div>
                    <div className="text-wrapper-9">Ambedkar Nagar</div>
                  </div>
                </div>
                <div className="overlap-wrapper">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-10">Liam Leo</div>
                    <div className="text-wrapper-11">Asha Worker</div>
                    <div className="group-17">
                      <div className="ellipse-4" />
                    </div>
                    <div className="text-wrapper-12">Ayodhya</div>
                  </div>
                </div>
                <div className="group-18">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-13">Emma Luna</div>
                    <div className="text-wrapper-14">Digital Assisatnt</div>
                    <div className="group-19">
                      <div className="ellipse-4" />
                    </div>
                    <div className="text-wrapper-15">Agra</div>
                  </div>
                </div>
                <div className="group-20">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-16">Isabella</div>
                    <div className="text-wrapper-17">Engineering Assisatnt</div>
                    <div className="ellipse-wrapper">
                      <div className="ellipse-3" />
                    </div>
                    <div className="text-wrapper-18">Gautam Budha Nagar</div>
                  </div>
                </div>
                <div className="group-21">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-19">Levi Thomas</div>
                    <div className="text-wrapper-20">Women Police</div>
                    <div className="ellipse-wrapper">
                      <div className="ellipse-3" />
                    </div>
                    <div className="text-wrapper-21">Jaunpur</div>
                  </div>
                </div>
                <div className="group-22">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-22">John Lucas</div>
                    <div className="text-wrapper-20">Current Worker</div>
                    <div className="group-17">
                      <div className="ellipse-4" />
                    </div>
                    <div className="text-wrapper-23">Balrampur</div>
                  </div>
                </div>
                <div className="group-23">
                  <div className="overlap">
                    <div className="text-wrapper-24">Name</div>
                    <div className="text-wrapper-25">Role</div>
                    <div className="text-wrapper-26">Status</div>
                    <div className="text-wrapper-27">Workplace</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rectangle-wrapper">
              <div className="rectangle-3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
