/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const ChevronDown4 = ({ opacity = "unset", className }) => {
  return (
    <svg
      className={`chevron-down-4 ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.5 6.75L9 11.25L13.5 6.75"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={opacity}
        strokeWidth="2"
      />
    </svg>
  );
};

ChevronDown4.propTypes = {
  opacity: PropTypes.string,
};
