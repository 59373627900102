import React, { useState } from "react";
import { TextMedium } from "./components/TextMedium";
import { IconMenuDashboard } from "./components/IconMenuDashboard";
import { TextMediumWrapper } from "./components/TextMediumWrapper";
import { File031 } from "./icons/File031";
import { MarkerPin05 } from "./icons/MarkerPin05";
import { MarkerPin061 } from "./icons/MarkerPin061";
import userIcon from "./img/group-48123.png"; // Import the user icon image
import ellipseIcon from "./img/ellipse-61.png";
import imageicon from "./img/group-1000002761.png";
import secondimageicon from "./img/group-1000002766.png";
import './usermanagement.css';

export const User = () => {
  return (
    <div className="user">
      <div className="group-9">
        <div className="group-10">
          <div className="group-11">
            <div className="group-12">
              <div className="overlap-group-wrapper">
                <div className="overlap-group-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.8102 19.5901L14.6466 14.5411C15.9988 13.0812 16.8296 11.1506 16.8296 9.02608C16.829 4.4814 13.0618 0.797729 8.41448 0.797729C3.76713 0.797729 0 4.4814 0 9.02608C0 13.5708 3.76713 17.2544 8.41448 17.2544C10.4225 17.2544 12.2641 16.5643 13.7107 15.4169L18.8944 20.4859C19.147 20.7331 19.5571 20.7331 19.8097 20.4859C20.0628 20.2387 20.0628 19.8374 19.8102 19.5901ZM8.41448 15.9884C4.48232 15.9884 1.29469 12.8713 1.29469 9.02608C1.29469 5.18086 4.48232 2.06371 8.41448 2.06371C12.3467 2.06371 15.5343 5.18086 15.5343 9.02608C15.5343 12.8713 12.3467 15.9884 8.41448 15.9884Z"
                      fill="#4270E7"
                      fillOpacity="0.95"
                    />
                  </svg>
                  <div className="text-wrapper-4">Search User</div>
                </div>
              </div>
              <img className="img" alt="Group" src={userIcon} />
            </div>
            <div className="group-13">
              <div className="group-14">
                <img
                  className="group-15"
                  alt="Group"
                  src={imageicon}
                />
                <div className="group-16">
                  <div className="group-17">
                    <div className="group-18">
                      <div className="text-wrapper-5">+91 345 069 378</div>
                      <div className="text-wrapper-6">Rampura, Agra</div>
                      <div className="text-wrapper-7">Address:</div>
                      <div className="text-wrapper-8">Alex@gmail.com</div>
                      <img
                        className="ellipse"
                        alt="Ellipse"
                        src={ellipseIcon}
                      />
                      <div className="text-wrapper-9">Henry Jack</div>
                    </div>
                  </div>
                </div>
                <img className="group-19" alt="Group" src={imageicon} />
              </div>
              <div className="group-20">
                <img className="group-15" alt="Group" src={secondimageicon} />
                <img className="group-19" alt="Group" src={secondimageicon} />
                <img className="group-21" alt="Group" src={secondimageicon} />
              </div>
              <div className="group-22">
                <img className="group-21" alt="Group" src={secondimageicon} />
                <img className="group-23" alt="Group" src={secondimageicon} />
                <img className="group-19" alt="Group" src={secondimageicon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
