import React, { useState } from "react";
// import { Group } from "./components/Group";
import { IconMenuDashboard } from "./components/IconMenuDashboard";
import { TextMedium } from "./components/TextMedium";
import { TextMediumWrapper } from "./components/TextMediumWrapper";
import { File031 } from "./icons/File031";
import { HelpCircle } from "./icons/HelpCircle";
import { LogOut04 } from "./icons/LogOut04";
import { MarkerPin053 } from "./icons/MarkerPin053";
import { MarkerPin062 } from "./icons/MarkerPin062";
import { Settings01 } from "./icons/Settings01";
import { Users01 } from "./icons/Users01";
import { Users031 } from "./icons/Users031";
import { useNavigate } from "react-router-dom"; // Import useNavigate

import searchIcon from "./images/searchIcon.svg";
import notificationIcon from "./images/notifications.svg";
import avatarManHeader from "./images/avatar-man-header.svg";
import "./dashboard.css";
import { Group } from "./dashboarddata/dashboarddata";
import { User } from "./Usermanagementdata/usermanagement";
import { Role } from "./role-management/rolemanagement";
import { District } from "./district/district";
import { Taluka } from "./taluka/taluka";
import { Reports } from "./reports/report";
export const Dashboard = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const [activeTab, setActiveTab] = useState("dashboard");

  const showDashboardData = () => {
    setActiveTab("dashboard");
  }

  

  const showUserManagementData = () => {
    setActiveTab("userManagement");
  };

  const showGroupData = () => {
    setActiveTab("dashboard");
  };

  const showRoleManagementData = () => {
    setActiveTab("roleManagement");
  };

  const showDistrictData = () => {
    setActiveTab("district");
  };

  const showTalukaData = () => {
    setActiveTab("taluka");
  };

  const showReportsData = () => {
    setActiveTab("reports");
  };

  const renderActiveTab = () => {
    if (activeTab === "userManagement") {
      return <User />;
    } else if (activeTab === "roleManagement") {
      return <Role />;
    } else if (activeTab === "district") {
      return <District />;
    } else if (activeTab === "taluka") {
      return <Taluka />;
    } else if (activeTab === "reports") {
      return <Reports />;
    } else {
      return <Group />;
    }
  };

  return (
    <div className="dashboard">
      <div className="div-wrapper">
        <div className="overlap-5">
          <div className="group-2">
            <div className="overlap-6">
              <div className="group-3">
                <div className="overlap-group-2">
                  <div className="text-wrapper-6">Logo</div>
                </div>
              </div>
              <div className="HEADER-wrapper">
                <div className="HEADER">
                  <div className="overlap-7">
                    <div className="header">
                      <div className="frame">
                        <div className="headertabs-default">
                          <div className="headertabitem" />
                        </div>
                      </div>
                      <div className="search-bar">
                        <div className="user-name">
                          <div className="photo">
                            <div className="overlap-group-3">
                              <img
                                className="avatar-man-header"
                                alt="Avatar man header"
                                src={avatarManHeader}
                              />
                              <div className="profile-circle" />
                            </div>
                          </div>
                          <div className="name">Snehal Hule</div>
                          <div className="icon-notifications">
                            <div className="overlap-8">
                              <img
                                className="vector"
                                alt="Vector"
                                src={notificationIcon}
                              />
                              <div className="new" />
                            </div>
                          </div>
                        </div>
                        <div className="group-4">
                          <div className="overlap-9">
                            <img
                              className="img"
                              alt="Vector"
                              src={searchIcon}
                            />
                            <div className="text-wrapper-7">Search Data</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-wrapper-8">Good Morning, Snehal</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-wrapper">
            <div className="group-5">
              <div className="group-6">{renderActiveTab()}</div>
              <div className="group-7">
                <div className="group-8">
                  <div className="side-nav-devider" />
                  <div className="tasks" onClick={showUserManagementData}>
                    <TextMedium
                      className="text-medium-13-darkblue"
                      divClassName="text-medium-instance"
                      text="User Management"
                    />
                    <Users01 className="icon-instance-node" color="white" />
                  </div>
                  <div className="group-9">
                    <Settings01 className="icon-instance-node" />
                    <div className="settings">
                      <TextMedium
                        className="text-medium-13-darkblue-instance"
                        divClassName="design-component-instance-node"
                        text="Settings"
                      />
                    </div>
                  </div>
                  <div className="group-10">
                    <LogOut04 className="log-out" color="white" />
                    <div className="text-medium-13-darkblue-wrapper">
                      <TextMedium
                        className="text-medium-13-darkblue-instance"
                        divClassName="text-medium-2"
                        text="Log Out"
                      />
                    </div>
                  </div>
                  <div className="text-wrapper-9">MENU</div>
                  <div onClick={showRoleManagementData}>
                    <TextMedium
                      className="text-medium-3"
                      divClassName="text-medium-instance"
                      text="Role Management"
                    />
                    <Users031 className="users" color="white" />
                  </div>

                  <div className="group-11">
                    <div className="contacts" onClick={showDistrictData}>
                      <TextMedium
                        className="text-medium-13-darkblue-instance"
                        divClassName="text-medium-instance"
                        text="District"
                      />
                    </div>
                    <MarkerPin053
                      className="icon-instance-node"
                      color="white"
                    />
                  </div>
                  <div className="group-12">
                    <div className="chat" onClick={showTalukaData}>
                      <TextMedium
                        className="text-medium-13-darkblue-instance"
                        divClassName="text-medium-instance"
                        text="Taluka"
                      />
                    </div>
                    <MarkerPin062
                      className="icon-instance-node"
                      color="white"
                    />
                  </div>
                  <div className="group-13">
                    <div className="deals" onClick={showReportsData}>
                      <TextMedium
                        className="text-medium-13-darkblue-instance"
                        divClassName="text-medium-instance"
                        text="Reports"
                      />
                    </div>
                    <File031 className="icon-instance-node" color="white" />
                  </div>
                  <div className="text-wrapper-10">Support</div>
                  <div className="group-14">
                    <div className="text-medium-instance-wrapper">
                      <TextMedium
                        className="text-medium-13-darkblue-instance"
                        divClassName="text-medium-4"
                        text="Help"
                      />
                    </div>
                    <HelpCircle className="icon-instance-node" />
                  </div>
                  <div className="group-15">
                    <div className="overlap-group-4">
                      <div className="rectangle-2" />
                      <div className="div-2" onClick={showDashboardData}>
                        <TextMediumWrapper
                          className="text-medium-13-accent"
                          divClassName="text-medium-13-accent-instance"
                          text="Dashboard"
                        />
                        <IconMenuDashboard />
                      </div>
                      <div className="rectangle-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
