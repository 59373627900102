/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const MarkerPin052 = ({ color = "#00A187", opacity = "unset", className }) => {
  return (
    <svg
      className={`marker-pin-05-2 ${className}`}
      fill="none"
      height="60"
      viewBox="0 0 60 60"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M40 33.4361C48.8295 35.1721 55 39.1368 55 43.75C55 49.9632 43.8071 55 30 55C16.1929 55 5 49.9632 5 43.75C5 39.1368 11.1705 35.1721 20 33.4361M30 42.5V7.5L43.2943 15.6811C44.264 16.2779 44.7489 16.5762 44.9035 16.9521C45.0384 17.28 45.0278 17.6497 44.8742 17.9693C44.6981 18.3356 44.1968 18.6056 43.1943 19.1454L30 26.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={opacity}
        strokeWidth="2"
      />
    </svg>
  );
};

MarkerPin052.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.string,
};
