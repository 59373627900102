/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const File031 = ({ color = "black", className }) => {
  return (
    <svg
      className={`file-03-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M6.66665 11.6667V15M13.3333 10V15M9.99998 6.66667V15M16.6666 5.66667V14.3333C16.6666 15.7335 16.6666 16.4335 16.3942 16.9683C16.1545 17.4387 15.772 17.8212 15.3016 18.0609C14.7668 18.3333 14.0668 18.3333 12.6666 18.3333H7.33331C5.93318 18.3333 5.23312 18.3333 4.69834 18.0609C4.22793 17.8212 3.84548 17.4387 3.6058 16.9683C3.33331 16.4335 3.33331 15.7335 3.33331 14.3333V5.66667C3.33331 4.26654 3.33331 3.56647 3.6058 3.03169C3.84548 2.56129 4.22793 2.17883 4.69834 1.93915C5.23312 1.66667 5.93318 1.66667 7.33331 1.66667H12.6666C14.0668 1.66667 14.7668 1.66667 15.3016 1.93915C15.772 2.17883 16.1545 2.56129 16.3942 3.03169C16.6666 3.56647 16.6666 4.26654 16.6666 5.66667Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

File031.propTypes = {
  color: PropTypes.string,
};
