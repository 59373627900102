import React from "react";
import { MarkerPin } from "./components/MarkerPin";
import { MarkerPinWrapper } from "./components/MarkerPinWrapper";
import { ChevronDown1 } from "./icons/ChevronDown1";
import { ChevronDown4 } from "./icons/ChevronDown4";
import { File031 } from "./icons/File031";
import "./report.css";

import groupbaricon from "./img/group.png";
import groupfirsticon from "./img/group-1.png";
import groupsecondicon from "./img/group-2.png";
import lineicon from "./img/line-1515.svg";

export const Reports = () => {
  return (
    <div className="reports">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="group">
            <div className="group-3">
              <div className="overlap-group-wrapper">
                <div className="overlap-3">
                  <div className="rectangle-2" />
                  <div className="text-wrapper-3">Total Responses</div>
                  <div className="text-wrapper-4">65</div>
                  <div className="div-wrapper">
                    <div className="overlap-4">
                      <div className="radial-progress">
                        <div className="element-wrapper">
                          <svg
                            width="24"
                            height="18"
                            viewBox="0 0 24 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.90039 3.81818C7.13466 3.81818 10.1717 4.66266 12.803 6.14315C16.055 7.97287 18.6873 10.774 20.3064 14.1531"
                              stroke="#413EF7"
                              stroke-width="7"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="today-s-updates">
                        Today’s
                        <br /> updates
                      </div>
                      <svg
                        width="2"
                        height="52"
                        viewBox="0 0 2 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="1.25"
                          y1="51.5"
                          x2="1.25"
                          y2="0.5"
                          stroke="white"
                        />
                      </svg>
                      <div className="text-wrapper-5">Avg Time</div>
                      <div className="text-wrapper-6">6 min</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-4">
                <div className="overlap-5">
                  <div className="overlap-group-3">
                    <div className="text-wrapper-7">
                      No of Districts Updated
                    </div>
                  </div>
                  <div className="text-wrapper-8">60</div>
                  <MarkerPin />
                </div>
              </div>
              <div className="group-5">
                <div className="overlap-6">
                  <div className="rectangle-3" />
                  <div className="group-6">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-9">
                        No of Talukas Updated
                      </div>
                    </div>
                  </div>
                  <MarkerPinWrapper  />
                  <div className="text-wrapper-10">12</div>
                </div>
              </div>
              <div className="group-7">
                <div className="overlap-7">
                  <div className="rectangle-4" />
                  <ChevronDown1 className="chevron-down" />
                  <ChevronDown4 className="chevron-down-4" opacity="0.8" />
                  <ChevronDown1 className="chevron-down-1" />
                  <div className="text-wrapper-11">Highlights</div>
                  <div className="text-wrapper-12">Districts</div>
                  <div className="text-wrapper-13">Talukas</div>
                  <div className="text-wrapper-14">Grama sabha</div>
                  <div className="text-wrapper-15">May</div>
                  <img className="line-2" alt="Line" src={lineicon} />
                  <div className="text-wrapper-16">Sasni</div>
                  <div className="text-wrapper-17">Baber</div>
                  <div className="text-wrapper-18">Hathras - UP</div>
                  <div className="text-wrapper-19">Banda - UP</div>
                  <div className="text-wrapper-20">82.16%</div>
                  <div className="text-wrapper-21">65.85%</div>
                </div>
              </div>
              <div className="group-8">
                <div className="overlap-8">
                  <div className="overlap-9">
                    <div className="group-9">
                      <div className="overlap-10">
                        <div className="group-10">
                          <div className="overlap-11">
                            <div className="group-11">
                              <div className="overlap-group-5">
                                <svg
                                  width="2"
                                  height="94"
                                  viewBox="0 0 2 94"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.522461 0.624474V93.1139"
                                    stroke="#B8B8B8"
                                  />
                                </svg>

                                <div className="group-11">
                                  <div className="group-12">
                                    <svg
                                      width="4"
                                      height="2"
                                      viewBox="0 0 4 2"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.594727 1.11392H3.52287"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>
                                    <div className="text-wrapper-22">0</div>
                                  </div>
                                  <div className="group-13">
                                    <svg
                                      width="4"
                                      height="2"
                                      viewBox="0 0 4 2"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.594727 0.991554H3.52287"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>
                                    <div className="text-wrapper-22">2</div>
                                  </div>
                                  <div className="group-14">
                                    <svg
                                      width="4"
                                      height="2"
                                      viewBox="0 0 4 2"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.594727 0.869202H3.52287"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>
                                    <div className="text-wrapper-23">4</div>
                                  </div>
                                  <div className="group-15">
                                    <svg
                                      width="4"
                                      height="2"
                                      viewBox="0 0 4 2"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.594727 0.746834H3.52287"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>
                                    <div className="text-wrapper-23">6</div>
                                  </div>
                                  <div className="group-16">
                                    <svg
                                      width="4"
                                      height="2"
                                      viewBox="0 0 4 2"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.59375 0.624474H3.52189"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>

                                    <div className="text-wrapper-24">8</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="group-17">
                              <div className="overlap-12">
                                <svg
                                  width="293"
                                  height="2"
                                  viewBox="0 0 293 2"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0.522461 1.11392H292.361"
                                    stroke="#B8B8B8"
                                  />
                                </svg>
                                <div className="group-18">
                                  <img
                                    className="group-19"
                                    alt="Group"
                                    src={groupsecondicon}
                                  />
                                  <img
                                    className="group-20"
                                    alt="Group"
                                    src={groupfirsticon}
                                  />
                                  <div className="text-wrapper-25">Tiloi</div>
                                  <div className="overlap-13">
                                    <img
                                      className="img-2"
                                      alt="Group"
                                      src={groupbaricon}
                                    />
                                    <svg
                                      width="2"
                                      height="5"
                                      viewBox="0 0 2 5"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 4.33333V0.864975"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>
                                  </div>
                                  <div className="text-wrapper-26">Khekada</div>
                                  <svg
                                    width="30"
                                    height="24"
                                    viewBox="0 0 30 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0 1.52588e-05H30V23.1224H0V1.52588e-05Z"
                                      fill="#263238"
                                      fill-opacity="0.16"
                                    />
                                  </svg>
                                  <svg
                                    width="2"
                                    height="4"
                                    viewBox="0 0 2 4"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1.35547 3.59073V0.122375"
                                      stroke="#B8B8B8"
                                    />
                                  </svg>

                                  <div className="text-wrapper-27">Sandila</div>
                                  <div className="overlap-group-6">
                                    <svg
                                      width="30"
                                      height="54"
                                      viewBox="0 0 30 54"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 1.52588e-05H30V53.1815H0V1.52588e-05Z"
                                        fill="#263238"
                                        fill-opacity="0.16"
                                      />
                                    </svg>
                                    <svg
                                      width="2"
                                      height="4"
                                      viewBox="0 0 2 4"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0.699219 3.46837V1.52588e-05"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>
                                  </div>
                                  <div className="text-wrapper-28">Sasni</div>
                                  <div className="overlap-14">
                                    <svg
                                      width="39"
                                      height="82"
                                      viewBox="0 0 39 82"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M0 0H38.5539V81.3907H0V0Z"
                                        fill="#263238"
                                        fill-opacity="0.16"
                                      />
                                    </svg>
                                    <svg
                                      width="2"
                                      height="4"
                                      viewBox="0 0 2 4"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1.23633 3.46837V1.52588e-05"
                                        stroke="#B8B8B8"
                                      />
                                    </svg>
                                  </div>
                                  <div className="text-wrapper-29">Baber</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="group-21">
                          <div className="overlap-15">
                            <div className="text-wrapper-30">K</div>
                          </div>
                        </div>
                        <div className="group-22">
                          <div className="overlap-16">
                            <div className="text-wrapper-30">T</div>
                          </div>
                        </div>
                        <div className="group-23">
                          <div className="overlap-17">
                            <div className="text-wrapper-30">B</div>
                          </div>
                        </div>
                        <div className="group-24">
                          <div className="overlap-18">
                            <div className="text-wrapper-30">S</div>
                          </div>
                        </div>
                        <div className="group-25">
                          <div className="overlap-19">
                            <div className="text-wrapper-30">S</div>
                          </div>
                        </div>
                      </div>
                      <ChevronDown4
                        className="chevron-down-4-instance"
                        opacity="0.8"
                      />
                    </div>
                    <div className="text-wrapper-31">Vertical Performance</div>
                    <div className="text-wrapper-32">Talukas</div>
                    <div className="text-wrapper-33">Months</div>
                  </div>
                  <div className="text-wrapper-34">Talukas</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
