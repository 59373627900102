import React from "react";
import { MarkerPin051 } from "./icons/MarkerPin051";
import groupimageiconone from "./img/group-1000002793.png";
import groupimageicontwo from "./img/group-48126.png";
import groupimageiconthree from "./img/group-1000002795.png";
import groupimageiconfour from "./img/group-1000002790.png";
import groupimageiconfive from "./img/group-1000002791.png";
import groupimageiconsix from "./img/group-1000002798.png";
import groupimageiconseven from "./img/group-1000002800.png";
import SearchdistrictIcon from "./img/vector-1.svg";
import AdddistrictImage from "./img/group-48126.png";
import "./district.css";

export const District = () => {
  return (
    <div className="d-istrict">
      {/* <div className="overlap-group"> */}
        <div className="overlap-group-wrapper">
          <div className="overlap-group-2">
            <img className="vector" alt="Vector" src={SearchdistrictIcon} />
            <div className="text-wrapper-16">Search District</div>
          </div>
          <div>
            <img className="img" alt="Group" src={AdddistrictImage} />
          </div>
          <svg
            width="63"
            height="1"
            viewBox="0 0 63 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="0.75"
              x2="63"
              y2="0.75"
              stroke="black"
              strokeOpacity="0.5"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <div className="group-11">
          <div className="group-12">
            <img className="group-13" alt="Group" src={groupimageiconone} />
            <img className="group-14" alt="Group" src={groupimageiconfive} />
            <img className="group-15" alt="Group" src={groupimageiconthree} />
            <img className="group-16" alt="Group" src={groupimageiconfour} />
            <img className="group-17" alt="Group" src={groupimageiconseven} />
            <img className="group-18" alt="Group" src={groupimageiconsix} />
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};
