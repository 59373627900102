/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const MarkerPin053 = ({ color = "black", className }) => {
  return (
    <svg
      className={`marker-pin-05-3 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.3334 11.1454C16.2765 11.724 18.3334 13.0456 18.3334 14.5833C18.3334 16.6544 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 16.6544 1.66669 14.5833C1.66669 13.0456 3.72351 11.724 6.66669 11.1454M10 14.1667V2.5L14.4315 5.22704C14.7547 5.42595 14.9163 5.52541 14.9679 5.65071C15.0128 5.76 15.0093 5.88325 14.9581 5.98976C14.8994 6.11188 14.7323 6.20185 14.3981 6.38179L10 8.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

MarkerPin053.propTypes = {
  color: PropTypes.string,
};
