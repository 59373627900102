/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { MarkerPin06 } from "../../icons/MarkerPin06";
import "./style.css";

export const MarkerPinWrapper = () => {
  return <MarkerPin06 className="marker-pin" color="black" />;
};
