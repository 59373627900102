/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const MarkerPin052 = ({ color = "black", className }) => {
  return (
    <svg
      className={`marker-pin-05-2 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M13.3337 11.1454C16.2768 11.724 18.3337 13.0456 18.3337 14.5833C18.3337 16.6544 14.6027 18.3333 10.0003 18.3333C5.39795 18.3333 1.66699 16.6544 1.66699 14.5833C1.66699 13.0456 3.72382 11.724 6.66699 11.1454M10.0003 14.1667V2.5L14.4318 5.22704C14.755 5.42595 14.9166 5.52541 14.9682 5.65071C15.0131 5.76 15.0096 5.88325 14.9584 5.98976C14.8997 6.11188 14.7326 6.20185 14.3984 6.38179L10.0003 8.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
};

MarkerPin052.propTypes = {
  color: PropTypes.string,
};
