/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HelpCircle = ({ className }) => {
  return (
    <svg
      className={`help-circle ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_3_228)">
        <path
          className="path"
          d="M7.57502 7.50001C7.77094 6.94306 8.15765 6.47343 8.66665 6.17428C9.17565 5.87514 9.7741 5.76579 10.356 5.8656C10.9379 5.96541 11.4657 6.26794 11.8459 6.71961C12.2261 7.17128 12.4342 7.74294 12.4334 8.33334C12.4334 10 9.93335 10.8333 9.93335 10.8333M10 14.1667H10.0084M18.3334 10C18.3334 14.6024 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 14.6024 1.66669 10C1.66669 5.39763 5.39765 1.66667 10 1.66667C14.6024 1.66667 18.3334 5.39763 18.3334 10Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_3_228">
          <rect className="rect" fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
