import React from 'react';
import { Dashboard } from "./dashboard/dashboard";
import { User } from "./dashboard/Usermanagementdata/usermanagement";
import { Role } from './dashboard/role-management/rolemanagement';
import { District } from './dashboard/district/district';
import { Taluka } from './dashboard/taluka/taluka';
import { Reports } from './dashboard/reports/report';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/user-management" element={<User />} />
        <Route path="/role-management" element={<Role />} />  
        <Route path="/district" element={<District />} />    
        <Route path="/taluka" element={<Taluka />} /> 
        <Route path="/reports" element={<Reports />} /> 
      </Routes>
    </Router>
  );
}

export default App;
